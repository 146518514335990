import React from "react";

const navigationConfig = [
  {
    id: "home",
    title: "HOME",
    type: "item",
    icon: (
      <i
        className="bi bi-house"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "scorecards",
    title: "SCORECARDS",
    type: "collapse",
    icon: (
      <i
        className="bi bi-bar-chart"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    children: [
      {
        id: "property",
        title: "Property",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/scorecards/property",
      },
      {
        id: "admin-sales",
        title: "Admin/Sales",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/scorecards/admin-sales",
      },
      {
        id: "housekeeping-maintenance",
        title: "Housekeeping/Maintenance",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/scorecards/housekeeping-maintenance",
      },
    ],
  },
  // {
  //   id: "documents",
  //   title: "DOCUMENTS",
  //   type: "collapse",
  //   icon: (
  //     <i
  //       className="bi bi-file-earmark-text"
  //       style={{
  //         marginRight: "1rem",
  //         fontSize: "1.5rem",
  //         paddingLeft: "0.3rem",
  //       }}
  //     />
  //   ),
  //   permissions: ["admin", "editor"],
  //   children: [
  //     {
  //       id: "payroll-reports",
  //       title: "Payroll Reports",
  //       type: "item",
  //       permissions: ["admin", "editor"],
  //       navLink: "/documents/payroll-reports",
  //     },
  //     {
  //       id: "end-of-month-reports",
  //       title: "End of Month Reports",
  //       type: "item",
  //       permissions: ["admin", "editor"],
  //       navLink: "/documents/end-of-month-reports",
  //     },
  //   ],
  // },
  {
    id: "training",
    title: "TRAINING",
    type: "item",
    icon: (
      <i
        className="bi bi-person-check"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    navLink: "/training-material",
  },
  {
    id: "data",
    title: "DATA",
    type: "collapse",
    icon: (
      <i
        className="bi bi-file-earmark-text"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    children: [
      {
        id: "sales-indicators",
        title: "Sales Indicators",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/data/sales-indicators",
      },
      {
        id: "revenue",
        title: "Revenue",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/data/revenue",
      },
    ],
  },
  {
    id: "rate-efficiencies",
    title: "RATE EFFICIENCIES",
    type: "collapse",
    icon: (
      <i
        className="bi bi-graph-up-arrow"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    children: [
      {
        id: "market-segmentation",
        title: "Market Segmentation",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/rate-efficiencies/market-segmentation",
      },
      {
        id: "revenue-summary",
        title: "Revenue Summary",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/rate-efficiencies/revenue-summary",
      },
      {
        id: "property-rankings",
        title: "Property Rankings",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/rate-efficiencies/property-rankings",
      },
    ],
  },

  {
    id: "import",
    title: "IMPORT",
    type: "collapse",
    icon: (
      <i
        className="bi bi-download"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    children: [
      {
        id: "income-statements",
        title: "Income Statements",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/income-statements",
      },
      {
        id: "income-statements-daily",
        title: "Income Statements Daily",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/income-statements-daily",
      },
      {
        id: "str-reports",
        title: "STR Reports",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/str-reports",
      },
      {
        id: "str-reports-daily",
        title: "STR Reports Daily",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/str-reports-daily",
      },
      {
        id: "customer-indicators",
        title: "Customer Indicators",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/customer-indicators",
      },
      {
        id: "customer-indicators-daily",
        title: "Customer Indicators Daily",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/customer-indicators-daily",
      },
      {
        id: "general-cleans",
        title: "General Cleans",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/general-cleans",
      },
      {
        id: "preventative-maintenance",
        title: "Preventative Maintenance",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/preventative-maintenance",
      },
      {
        id: "response-rate",
        title: "Response Rate",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/response-rate",
      },
      {
        id: "gaps",
        title: "Missing Reports - Gaps",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/gaps",
      },
      {
        id: "budget",
        title: "Budget",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/import/budget",
      },
    ],
  },
  {
    id: "helpCenter",
    title: "HELP CENTER",
    type: "item",
    icon: (
      <i
        className="bi bi-question-circle"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    navLink: "/help-center",
  },
  {
    id: "users-roles",
    title: "USERS & ROLES",
    type: "collapse",
    icon: (
      <i
        className="bi bi-people"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    children: [
      {
        id: "users",
        title: "Users",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/users-roles/users",
      },
      {
        id: "groups",
        title: "Groups",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/users-roles/groups",
      },
      {
        id: "properties",
        title: "Properties",
        type: "item",
        permissions: ["admin", "editor"],
        navLink: "/users-roles/properties",
      },
    ],
  },
  {
    id: "settings",
    title: "SETTINGS",
    type: "item",
    icon: (
      <i
        className="bi bi-gear"
        style={{
          marginRight: "1rem",
          fontSize: "1.5rem",
          paddingLeft: "0.3rem",
        }}
      />
    ),
    permissions: ["admin", "editor"],
    navLink: "/settings",
  },
  // {
  //   id: "import-files",
  //   title: "Import Files",
  //   type: "collapse",
  //   icon: (
  //     <i
  //       className="bi bi-download"
  //       style={{
  //         marginRight: "1rem",
  //         fontSize: "1.5rem",
  //         paddingLeft: "0.3rem",
  //       }}
  //     />
  //   ),
  //   permissions: ["admin", "editor"],
  //   children: [
  //     {
  //       id: "income-statement",
  //       title: "Income Statement",
  //       type: "item",
  //       icon: (
  //         <i
  //           className="bi bi-circle"
  //           style={{ marginRight: "1.4rem", fontSize: "0.7rem" }}
  //         />
  //       ),
  //       permissions: ["admin", "editor"],
  //       navLink: "/import-files/income-statement"
  //     },
  //     {
  //       id: "str-report",
  //       title: "STR Report",
  //       type: "item",
  //       icon: (
  //         <i
  //           className="bi bi-circle"
  //           style={{ marginRight: "1.4rem", fontSize: "0.7rem" }}
  //         />
  //       ),
  //       permissions: ["admin", "editor"],
  //       navLink: "/import-files/str-report",
  //     },
  //     {
  //       id: "customer-indicators",
  //       title: "Customer Indicators",
  //       type: "item",
  //       icon: (
  //         <i
  //           className="bi bi-circle"
  //           style={{ marginRight: "1.4rem", fontSize: "0.7rem" }}
  //         />
  //       ),
  //       permissions: ["admin", "editor"],
  //       navLink: "/import-files/customer-indicators",
  //     },
  //   ],
  // },
  // {
  //   id: "scorecards",
  //   title: "Scorecards",
  //   type: "item",
  //   icon: (
  //     <i
  //       className="bi bi-card-text"
  //       style={{
  //         marginRight: "1rem",
  //         fontSize: "1.5rem",
  //         paddingLeft: "0.3rem",
  //       }}
  //     />
  //   ),
  //   permissions: ["admin", "editor"],
  //   navLink: "/scorecards",
  // },
];

export default navigationConfig;
