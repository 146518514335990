export const parseMonth = (periodMonth) => {
  const months = [
    { name: "January", key: "01" },
    { name: "February", key: "02" },
    { name: "March", key: "03" },
    { name: "April", key: "04" },
    { name: "May", key: "05" },
    { name: "June", key: "06" },
    { name: "July", key: "07" },
    { name: "August", key: "08" },
    { name: "September", key: "09" },
    { name: "October", key: "10" },
    { name: "November", key: "11" },
    { name: "December", key: "12" },
  ];

  const month = months.find((month) => periodMonth.substr(-2) === month.key);
  return month ? month.name : periodMonth;
};

export const parsePeriod = (periodMonth) => {
  const months = [
    { name: "January", key: "01" },
    { name: "February", key: "02" },
    { name: "March", key: "03" },
    { name: "April", key: "04" },
    { name: "May", key: "05" },
    { name: "June", key: "06" },
    { name: "July", key: "07" },
    { name: "August", key: "08" },
    { name: "September", key: "09" },
    { name: "October", key: "10" },
    { name: "November", key: "11" },
    { name: "December", key: "12" },
  ];

  const month = months.find((month) => periodMonth.substr(-2) === month.key);
  const year = periodMonth.split("-")[0];

  if (month) return `${month.name} ${year}`;
  return periodMonth;
};

export const parseNumber = (number) =>
  new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(number);

export const roundToTwoDecimals = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const getShortDate = (date) => {
  const newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const day = newDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getShortDateMMDDYYYY = (date) => {
  const newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const day = newDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
};

export const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const getMonthName = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[date.getMonth()];
};

export const accountingFormat = (number) => {
  if (number < 0) {
    return `(${Math.abs(number)})`;
  } else {
    return number.toString();
  }
};

export const parseAndAccountingFormat = (number) => {
  const roundedNumber = parseNumber(number);
  const formattedNumber = accountingFormat(roundedNumber);
  return formattedNumber;
};
